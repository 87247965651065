import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import { GraphQLClient, ClientContext } from "graphql-hooks";
// import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

// const client = new GraphQLClient({
//   url: `${process.env.REACT_APP_GRAPHQL_API_URL}/`
//   console.log(url)
// });
// const uri = process.env.REACT_APP_GRAPHQL_API_URL;
// console.log(uri);

// const client = new ApolloClient({
//   uri,
//   cache: new InMemoryCache(),
//   connectToDevTools: true,
// });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
