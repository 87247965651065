import React, { useState, useEffect } from "react";
import NobleSidebar from "./NobleSidebar";

const Principal = () => {
  const [data, setData] = useState(null);

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://cms.maitretech.com/elegancepublicschool/items/principle?fields=*.*"
      );
      const jsonData = await response.json();
      setData(jsonData?.data[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="container-fluid p-0">
        <div className="container-fluid latest_card_box_ad p-5">
          <div className="ourschoolboardeft px-0 text-left">
            <div className="princd">
              <div className="photos">
                <img
                  src={data?.principle_image?.data?.full_url}
                  alt="Principal" style={{width:"300px",height:"300px"}}
                />
                <div className="phname">
                  <b>Principal</b> - {data?.principle_name}
                </div>
              </div>

              <div className="director_mess">
                <h5>
                  <b>Principal Message -</b>
                </h5>
                <p>{data?.principle_message}</p>
              </div>
            </div>
          </div>

          <div className="ourschoolright p-4">
            <NobleSidebar />
          </div>
        </div>
      </div>
    </>
  );
};

export default Principal;
