import React from "react";
import "./css/Navbar.css";

const Navbar = () => {
  return (
    <>
      <div className="container-fluid navmain">
        <div className="navleft">
          <div className="logo">
            <img src="./images/logo.jpeg" alt="" />
          </div>
        </div>
        <div className="navmid">
          <h1>
            <span id="firsth">E</span>LEGANCE <span id="firsth">P</span>UBLIC{" "}
            <span id="firsth">S</span>CHOOL
          </h1>
          <p>Learning today. Leading tomorrow. Inspired Forever.</p>
        </div>
        <div className="navright">
          <div className="socialmedia">
            <a
              href="https://www.facebook.com/people/Elegance-public-school/100069118907495/"
              target="_blank"
              style={{ textDecoration: "none", color: "white" }}
            >
              {" "}
              <i className="ri-facebook-fill"></i>
            </a>

            <i className="ri-linkedin-box-fill"></i>
            <a
              href="https://youtube.com/@elegancepublicschool5802?si=_b6uToE2F8rNcJKn"
              target="_blank"
              style={{ textDecoration: "none", color: "white" }}
            >
            <i className="ri-youtube-line"></i></a>
          </div>
          <div className="seacrch">
            <input className="aa" type="search" />
            <div className="btn" style={{ backgroundColor: "#F8D34D" }}>
              Search
            </div>
          </div>
         
        </div>
      </div>
    </>
  );
};

export default Navbar;
