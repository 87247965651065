import React,{useState, useEffect} from "react";
import BannerSection from "./BannerSection";
import "./css/Facility.css";

const Facilities = () => {
  const [data, setData] = useState();

  const fetchData = async () => {
    try {
      const response = await fetch('https://cms.maitretech.com/elegancepublicschool/items/facility?fields=*.*');
      const jsonData = await response.json();
      setData(jsonData?.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="container-fluid p-0">
        <div className="container liscontainer">
          <div className="facilitycontent">
            {data &&
              data.map((facility, index) => (
                <div className="facilitCard" key={index}>
                  <div className="facilimages">
                    <img src={facility?.facility_image?.data?.full_url} alt="" />
                  </div>
                  <div className="facontent">
                    <div className="factitle">{facility?.facility_name}</div>
                    <p>{facility?.facility_data}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Facilities;