import React from "react";
import { NavLink } from "react-router-dom";
import "./css/Footer.css";

const Footer = () => {
  return (
    <>
      <div className="container-fluid footermain p-2">
        <div className="footermid">
          <div className="footerbottm">
            <div className="fbleft">
              <li className="my-3">
                <NavLink to="/">Home</NavLink>
              </li>
              {/* <li className="my-3">
                <NavLink to="/boarddirector">Board of Director</NavLink>
              </li> */}
              <li className="my-3">
                <NavLink to="/principal">Principal</NavLink>
              </li>
              <li className="my-3">
                <NavLink to="/gallery">Gallery</NavLink>
              </li>
              <li className="my-3">
                <NavLink to="/contactus" className="ex">
                  Contact Us
                </NavLink>
              </li>
              {/* <li className="my-3">
                <NavLink to="/ourschool">About Us</NavLink>
              </li> */}
            </div>
            <div className="fbright">
              <li className="my-3">
                <NavLink to="/ourschool">About Us</NavLink>
              </li>
              <li className="my-3">
                <NavLink to="/academicprocedure">Academic</NavLink>
              </li>
              <li className="my-3">
                <NavLink to="/facilities">Facilities</NavLink>
              </li>
            </div>
          </div>
          {/* <li className="extra">
                <NavLink to="/contactus" className="ex">Contact Us</NavLink>
              </li> */}
        </div>

        <div className="footerleft">
          <div className="logo">
            <img src="./images/logo.jpeg" alt="" />
          </div>
        </div>
        <div className="fbrightt">
          <div className="socialmedia1">
            <a
              href="https://www.facebook.com/people/Elegance-public-school/100069118907495/"
              target="_blank"
              style={{ textDecoration: "none", color: "white" }}
            >
              {" "}
              <i className="ri-facebook-fill"></i>
            </a>
            <i className="ri-linkedin-box-fill"></i>
            <a
              href="https://youtube.com/@elegancepublicschool5802?si=_b6uToE2F8rNcJKn"
              target="_blank"
              style={{ textDecoration: "none", color: "white" }}
            >
              <i className="ri-youtube-line"></i>
            </a>
          </div>
          <div className="seacrch">
            <input className="aa" type="search" />
            <div className="btn btn-lg" style={{ backgroundColor: "white" }}>
              <i className="ri-search-line"></i>
            </div>
          </div>
          <div class="image-container">
    <div>
        <a href="https://play.google.com/store/apps/details?id=com.uolo.notes" target="_blank">
            <img class="responsive-image" src="./images/gp.png" alt="Google Play Store"/>
        </a>
    </div>
    <div>
        <a href="https://apps.apple.com/in/app/uolo-learn/id1627069461" target="_blank">
            <img class="responsive-image" src="./images/image.png" alt="Apple App Store"/>
        </a>
    </div>
</div>

          <div className="copyright mt-2">
            <p id="copyr">
              © Made with &nbsp;
              <i
                className="ri-heart-fill"
                style={{ color: "red", fontSize: "20px" }}
              >
                {" "}
              </i>{" "}
              <a
                style={{ color: "white", textDecoration: "none" }}
                target="_blank"
                href="https://maitretech.com/"
              >
                &nbsp; by Maitretech Solution
              </a>
            </p>
          </div>
        </div>
      </div>

      <div className="fbtm">
        <p>
          Address - A/6 Subhash Colony Ashoka Garden Bhopal, Madhya Pradesh
          462023
        </p>
      </div>
    </>
  );
};

export default Footer;
