import React, { useState, useEffect } from "react";
import NobleSidebar from "./NobleSidebar";

const BoardD = () => {
  const [data, setData] = useState(null);

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://cms.maitretech.com/elegancepublicschool/items/director?fields=*.*"
      );
      const jsonData = await response.json();
      setData(jsonData?.data[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Check if data is available before rendering
  if (!data) {
    return <div>Loading...</div>;
  }

  // Destructure data to access director's information
  const { director_image, director_name, director_message } = data;

  return (
    <>
      <div className="container-fluid p-0">
        <div className="container-fluid latest_card_box_ad p-5">
          <div className="ourschoolboardeft px-0 text-left">
            <div className="princd">
              <div className="photos">
                <img
                  src={director_image.data.full_url} // Use the director's image URL
                  alt="Director" style={{width:"300px",height:"300px"}}
                />
                <div className="phname">
                  <b>Director</b> - {director_name}
                </div>
              </div>

              <div className="director_mess">
                <h5>
                  <b>Director Message -</b>
                </h5>
                <p>{director_message}</p>
              </div>
            </div>
          </div>

          <div className="ourschoolright p-4">
            <NobleSidebar />
          </div>
        </div>
      </div>
    </>
  );
};

export default BoardD;
