import React,{useState,useEffect} from "react";
import "./css/BannerS.css";
import "./css/LatestNews.css";
import "./css/Admission.css";
import "./css/HlastSection.css";

const BannerSection = () => {
 
  const [data, setData ] = useState([]) 


  const fetchData = async () => {

    try {
      const response = await fetch('https://cms.maitretech.com/elegancepublicschool/items/slider?fields=*.*');
      const jsonData = await response.json();
  
      const imageUrls = await Promise.all(jsonData.data[0].slider_images.map(async (item) => {
        const response = await fetch(`${process.env.REACT_APP_API_ASSETS}/${item.directus_files_id}`);        const fileData = await response.json();
        return fileData.data.data;
      }));
  
      setData(imageUrls);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };



  useEffect(()=>{
    fetchData()
  },[])



  return (
    <>
           <div className="container-fluid p-0">
      <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
        <ol className="carousel-indicators">
          {data.map((item, index) => (
            <li
              key={index}
              data-target="#carouselExampleIndicators"
              data-slide-to={index}
              className={index === 0 ? "active" : ""}
            ></li>
          ))}
        </ol>
        <div className="carousel-inner" role="listbox">
          {data.map((item, index) => (
            <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`}>
              <img className="d-block slideimage" src={item.full_url} alt={`Slide ${index + 1}`} />
            </div>
          ))}
        </div>
        <a
          className="carousel-control-prev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
    </div>
    </>
  );
};

export default BannerSection;
