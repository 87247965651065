import React,{useState, useEffect} from "react";
import "./css/ourschool.css";
import NobleSidebar from "./NobleSidebar";

const OurSchool = () => {



  const [data , setData ] = useState()

  const fetchData = async () => {

    try {
      const response = await fetch('https://cms.maitretech.com/elegancepublicschool/items/our_school?fields=*.*');
      const jsonData = await response.json();
      setData(jsonData?.data[0]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };



  useEffect(()=>{
    fetchData()
  },[])






  return (
    <>
      <div className="container-fluid p-0">
        <div className="container-fluid d-flex latest_card_box_ad p-0">
          <div className="ourschooleft px-5 text-left">
            {/* <h4 className="kalur text-center" style={{color:"black", fontWeight:"bold"}}>Our School</h4> */}
            <b>
              <p>
                <em>The Elegance Public School.</em>
              </p>
            </b>
            <b>
              <p>
                <em>
                 {data?.our_school_message}
                </em>
              </p>
            </b>
            <h5>Elegance Public School proud to offer:</h5>
            <ul className="px-3">
              <li>Elegance Public School is proud to offer:</li>
              <li>A great Indian environment for growing and learning</li>
              {/* <li>Indian Studies, CBSC, and programs</li> */}

              <li>and much more…</li>
            </ul>
            <p>
              We encourage all community members to discover the possibilities
              at the Elegance Public School!
            </p>
          </div>

          <div className="ourschoolright p-4">
            <NobleSidebar />
          </div>
        </div>
      </div>
    </>
  );
};

export default OurSchool;
