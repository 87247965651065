import React,{useState,useEffect} from "react";
import "./css/Gallery.css";
const Gallery = () => {




  const [data, setData ] = useState([]) 


  const fetchData = async () => {

    try {
      const response = await fetch('https://cms.maitretech.com/elegancepublicschool/items/gallery?fields=*.*');
      const jsonData = await response.json();
  
      const imageUrls = await Promise.all(jsonData.data[0].gallery_images.map(async (item) => {
        const response = await fetch(`${process.env.REACT_APP_API_ASSETS}/${item.directus_files_id}`);        const fileData = await response.json();
        return fileData.data.data;
      }));
  
      setData(imageUrls);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  useEffect(()=>{
    fetchData()
  },[])



  return (
    <>
      <div className="container-fluid media_top">
        <div className="mb-3 row">
          { data?.map((item, i) => {
                return (
                  <div key={i} className="col-lg-3 col-md-4 col-sm-6 ">
                    <img
                      src={item.full_url}
                      className="mt-3 imght "
                      alt="no_img"
                      style={{ width: "100%", height: "250px" }}
                    />
                  </div>
                );
              })}
            
        </div>
      </div>
    
    </>
  );
};

export default Gallery;
